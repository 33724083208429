import React, {Component} from 'react'
//import logo from './logo.svg';
import './App.css';

import FileLoad from './FileLoad.js'
import VisNetwork from './net.js'

class App extends Component {
  state = {
    status:0,
    acc:[],
    pr:[],
    ost:[],
    prov:[]
  }

  loadData=(acc,pr,ost,prov)=>{
    const status=1
    this.setState({status,acc,pr,ost,prov})
    //console.log(acc)

  }

  /*handleKeyPres=(e)=>{
    console.log(e)
    //console.log(document.activeElement)
    //if (e.key==='ArrowDown') console.log(document.activeElement.nodeName)
  }*/


  componentDidMount(){
    window.addEventListener('keydown', this.handleKeyPres)
  }

  render(){
    const {status,acc,pr}=this.state

    if (status===0) return <FileLoad loadData={this.loadData} />
    else return <VisNetwork NetData={{nodes:acc,edges:pr}} back={()=>{this.setState({status:0})}}/>
    /*return (
      <FileLoad />
      
      <div className="App" >
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
        </header>
      </div>
      
    )*/
  }
}

export default App;

import React, { useState }  from 'react'

function FileLoad({loadData}){

    const [File1, setFile1] = useState('')
    const [File2, setFile2] = useState('')
    const [File3, setFile3] = useState('')

    function GetFiles1(){
        if (!File1) return
        if (!File2) return
        if (!File3) return
        if (File1.name.substr(-4)!=='.csv') return
        if (File2.name.substr(-4)!=='.csv') return
        if (File3.name.substr(-4)!=='.csv') return
        let reader = new FileReader()
        reader.readAsText(File1,'CP1251')
        reader.onload = function() {
            GetFiles2(reader.result.split('\r\n'));
        }
        reader.onerror = function() {
            console.log(reader.error);
        }
    }
    function GetFiles2(arr1){
        let reader = new FileReader()
        reader.readAsText(File2,'CP1251')
        reader.onload = function() {
            GetFiles3(arr1,reader.result.split('\r\n'));
        }
        reader.onerror = function() {
            console.log(reader.error);
        }
    }
    function GetFiles3(arr1,arr2){
        let reader = new FileReader()
        reader.readAsText(File3,'CP1251')
        reader.onload = function() {
            GetFiles(arr1,arr2,reader.result.split('\r\n'));
        }
        reader.onerror = function() {
            console.log(reader.error);
        }
    }
    function GetFiles(arr1,arr2,arr3){
        let acc=[]
        let pr=[]
        let ost=[]
        let prov=[]
        arr1.shift()
        arr1.forEach(el => {
            const v=el.split(';')
            const os1=parseFloat(v[5])-parseFloat(v[8])
            const os2=parseFloat(v[6])-parseFloat(v[9])
            const os3=parseFloat(v[7])-parseFloat(v[10])
            const ind=acc.findIndex(el=>el.acc===v[0])
            if (ind===-1) acc.push({acc:v[0],os:os1,of:0})
            else acc[ind].os=acc[ind].os+os1
            ost.push({acc:v[0],an1:v[1],an2:v[2],an3:v[3],v:v[4],osn:os1,oqn:os2,ovn:os3,osf:0,oqf:0,ovf:0})
        })
        arr2.shift()
        arr2.forEach(el => {
            const v=el.split(';')
            const os1=parseFloat(v[5])-parseFloat(v[8])
            const os2=parseFloat(v[6])-parseFloat(v[9])
            const os3=parseFloat(v[7])-parseFloat(v[10])
            let ind=acc.findIndex(el=>el.acc===v[0])
            if (ind===-1) acc.push({acc:v[0],os:0,of:os1})
            else acc[ind].of=acc[ind].of+os1
            ind=ost.findIndex(el=>el.acc===v[0])
            if (ind===-1) ost.push({acc:v[0],an1:v[1],an2:v[2],an3:v[3],v:v[4],osn:0,oqn:0,ovn:0,osf:os1,oqf:os2,ovf:os3})
            else {
                ost[ind].osf=os1
                ost[ind].oqf=os2
                ost[ind].ovf=os3
            }
        })
        arr3.shift()
        arr3.forEach(el => {
            const v=el.split(';')
            let ind=acc.findIndex(el=>el.acc===v[2])
            if (ind===-1) acc.push({acc:v[2],os:0,of:0})
            ind=acc.findIndex(el=>el.acc===v[6])
            if (ind===-1) acc.push({acc:v[6],os:0,of:0})

            ind=pr.findIndex(el=>el.acd===v[2]&&el.acc===v[6])
            if (ind===-1) pr.push({acd:v[2],acc:v[6],s:parseFloat(v[11])})
            else pr[ind].s=pr[ind].s+parseFloat(v[11])
            v.push(v[2]+'_'+v[6])
            prov.push(v)
        })
        loadData(acc,pr,ost,prov)
    }
    
    return <div style={{top: '0', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <table className='form' style={{padding:'1.5rem 1rem 1rem 1rem'}}><tbody>
        <tr><td style={{border:'none'}}><label htmlFor='file1'>Файл залишків на початок: </label></td><td style={{border:'none'}}>
        <input type='file' accept='.csv' id='file1' onChange={(e)=>{setFile1(e.target.files[0])}} style={{width: '100%'}} /></td></tr>
        <tr><td style={{border:'none'}}><label htmlFor='file2'>Файл залишків на кінець: </label></td><td style={{border:'none'}}>
        <input type='file' accept='.csv' id='file2' onChange={(e)=>{setFile2(e.target.files[0])}} style={{width: '100%'}} /></td></tr>
        <tr><td style={{border:'none'}}><label htmlFor='file3'>Файл залишків на початок: </label></td><td style={{border:'none'}}>
        <input type='file' accept='.csv' id='file3' onChange={(e)=>{setFile3(e.target.files[0])}} style={{width: '100%'}} /></td></tr>

        <tr><td colSpan='2' style={{textAlign:'center', border:'none'}}><button type='submit' onClick={GetFiles1}>&nbsp; OK &nbsp;</button></td></tr>
        </tbody></table></div>

}

export default FileLoad
import React, { useEffect, useRef } from 'react';
import { DataSet, Network} from 'vis-network/standalone/esm/vis-network';

const VisNetwork = ({NetData,back}) => {
    const domNode = useRef(null);
    const network = useRef(null);

    /*const nodes = new DataSet([
        {id: '201', label:' \n<b>201</b>\n300.00', color:'#FB7E81'},
        {id: '631', label:'50.00\n<b>631</b>\n1000.00', color:'#FFFF00'},
        {id: '311', label:'2000.00\n<b>311</b>\n ', color:'#99FF99'},
        {id: '23', label:'\n<b>23</b>\n700.00'}
    ])*/
    let a=[]
    let b=[]
    if (NetData.nodes!==undefined) a=NetData.nodes.map((el)=>{let a=' '; let b=' '; if(el.os!==0) a=parseFloat(el.os).toFixed(2); if(el.of!==0) b=parseFloat(el.of).toFixed(2); return({id:el.acc,label:a+'\n<b>'+el.acc+'</b>\n'+b})})
    if (NetData.edges!==undefined) b=NetData.edges.map((el)=>{let a=' '; if(el.of!==0) a=parseFloat(el.s).toFixed(2); return({id:el.acd+'_'+el.acc,from:el.acc, to:el.acd,label:a,arrows:'to'})})
    console.log(b)
    const nodes = new DataSet(a)

    /*const edges = new DataSet([
        {id: '631_201',from: '631', to: '201', label:'1050.00', arrows:'to'},
        {id: '631_311',from: '631', to: '311', label:'500.00', arrows:'to'},
        {id: '311_631',from: '311', to: '631', label:'2500.00', arrows:'to'},
        {id: '201_23',from: '201', to: '23', label:'700.00', arrows:'to'}
    ])*/
    const edges = new DataSet(b)

    const data = {nodes, edges}

    const options = {
        edges: {font: {size: 12}},
        nodes: {shape: "box", physics:false, font: {multi: "html", size: 12, face: 'arial', bold: {size: 18, color: "#000000" }}}
    }

    useEffect(
    () => {
        //network.current = new Network(domNode.current, data, options);
        const net = new Network(domNode.current, data, options)
        network.current = net
        net.on("doubleClick", function (params) {
            if (params.nodes.length!==0) {
                alert('Табличка для ' + params.nodes[0]);
            } else if (params.edges.length!==0) {
                alert('Табличка для ' + params.edges[0]);
            }
            });
    },
    [domNode, network, data, options]
    )

  return (
    <>
    <button type='submit' onClick={back}>&nbsp; Назад &nbsp;</button>
    <div style={{width:'100%',height:'calc(100vh - 30px)'}} ref = { domNode } />
    </>
  );
};

export default VisNetwork;
